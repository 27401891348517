<template>
  <div>
    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>
        <!-- Field: Ref -->
        <b-col cols="12" md="3">
          <b-form-group :label="$t('Ref')" label-for="ref" debounce="500">
            <b-form-input id="ref" v-model="refValue" />
          </b-form-group>
        </b-col>

        <!-- Field: Profile -->
        <!----<b-col cols="12" md="2">
          <b-form-group :label="$t('Sub profile')" label-for="sub-profile">
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              input-id="sub-profile"
              v-model="subProfileValue"
              :options="subProfiles"
              label="name"
            />
          </b-form-group>
        </b-col>-->

        <!-- Field: Client -->
        <b-col cols="12" md="3">
          <b-form-group :label="$t('Client')" label-for="client">
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="clients"
              label="name"
              input-id="client"
              v-model="clientValue"
              :loading ="loading"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Delivery Date -->
        <b-col cols="12" md="3">
          <b-form-group label-for="shipping_date" :label="$t('Shipping date')">
            <flat-pickr
              v-model="shippingDateValue"
              input-id="shipping_date"
              class="form-control"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Emergency Degree -->
        <b-col cols="12" md="3">
          <b-form-group
            :label="$t('Emergency degree')"
            label-for="emergency-degree"
          >
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="emergencyDegrees"
              v-model="emergencyDegreeValue"
              input-id="emergency-degree"
              @search="fetchEmergencyDegreeOptions"
              label="name"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
    <b-card no-body class="mb-0">
      <b-table
        id="scrolle-table"
        ref="refOrderListTable"
        class="position-relative"
        :items="fetchOrders"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('No matching records found')"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            class="btn-icon"
            @click="consulteOrder(data.item)"
          >
            <feather-icon icon="EyeIcon" size="16" />
            <!--Consult-->
          </b-button>
          <!----<b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="consulteOrder(data.item)">
              <feather-icon icon="ClipboardIcon" />
              <span class="align-middle ml-50">{{ $t("Consultation") }}</span>
            </b-dropdown-item>
          </b-dropdown>-->

          <consulte-order
            :ref="'consulteOrder' + data.item.id + 'Ref'"
            :data="data"
            state-id="canceled"
          />
          <cancel-order
            :ref="'cancelOrder' + data.item.id + 'Ref'"
            :data="data"
            state-id="canceled"
          />
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{
              $t("Pagination text", [dataMeta.from, dataMeta.to, dataMeta.of])
            }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalOrders"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BPagination,
  BDropdown,
  BDropdownItem,
  BModal,
  VBModal,
  BCardText,
  BBadge,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import instance from "@/libs/axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import useOrderTrackingList from "./useOrderTrackingList";
import Ripple from "vue-ripple-directive";
import CancelOrder from "./CancelOrder.vue";
import ConsulteOrder from "./ConsulteOrder.vue";
export default {
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  components: {
    BButton,
    BDropdown,
    BDropdownItem,
    BMedia,
    BBadge,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
    flatPickr,
    BPagination,
    BModal,
    VBModal,
    BCardText,
    CancelOrder,
    ConsulteOrder,
  },
  props: {
    clients: Array,
    emergencyDegrees: Array,
    subProfiles: Array,
    loading :Boolean,
  },
  data() {
    return {
      ref: null,
      selectedSubProfile: null,
      selectedClient: null,
      dateDefault: null,
      selectedEmergencyDegree: null,
      timeoutId: null,
      clientsRes: [],
      emergencyDegreesRes: [],
    };
  },

  methods: {
    refreshTable() {
      this.refetchData();
    },
    cancelOrder(order) {
      this.$refs[`cancelOrder${order.id}Ref`].cancelOrder(
        order,
        this.refetchData
      );
    },
    consulteOrder(order) {
      this.$refs[`consulteOrder${order.id}Ref`].consulteOrder(order);
    },
    fetchClientOptions(search, loading) {
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(async () => {
        try {
          loading(true);
          const res = await instance.get("/parameters/clients/", {
            params: { search },
          });
          this.clientsRes = res.data.results;
        } catch (err) {
          const error = err.response
            ? Object.values(err.response.data)[0][0]
            : err.message;

          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Error"),
              text: error ?? this.$t("An error has occurred. Please try again"),
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        } finally {
          loading(false);
        }
      }, 500);
    },
    async fetchEmergencyDegreeOptions(search, loading) {
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(async () => {
        try {
          loading(true);
          const res = await instance.get("/parameters/emergency-degrees/", {
            params: { search },
          });
          this.emergencyDegreesRes = res.data.results;
        } catch (err) {
          const error = err.response
            ? Object.values(err.response.data)[0][0]
            : err.message;

          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Error"),
              text: error ?? this.$t("An error has occurred. Please try again"),
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        } finally {
          loading(false);
        }
      }, 500);
    },
  },

  setup(props) {
    const {
      fetchOrders,
      tableColumns,
      perPage,
      currentPage,
      totalOrders,
      dataMeta,
      perPageOptions,
      refValue,
      sortBy,
      isSortDirDesc,
      refOrderListTable,
      refetchData,
      subProfileValue,
      clientValue,
      shippingDateValue,
      emergencyDegreeValue,
    } = useOrderTrackingList("canceled");

    return {
      // Sidebar
      fetchOrders,
      tableColumns,
      perPage,
      currentPage,
      totalOrders,
      dataMeta,
      perPageOptions,
      refValue,
      sortBy,
      isSortDirDesc,
      refOrderListTable,
      refetchData,
      subProfileValue,
      clientValue,
      shippingDateValue,
      emergencyDegreeValue,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
