<template>
  <div>
    <b-modal
      ref="prepare-order-lines"
      no-close-on-backdrop
      cancel-variant="outline-secondary"
      centered
      size="lg"
      :title="$t('Alert')"
      @hidden="onPrepareOrderLinesHidden"
      @ok="prepareSelectedOrderLines"
    >
      <b-row>
        <b-col>
          <b-form-group :label="$t('Storekeeper')" style="font-size: 0.857rem">
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              input-id="sub-profile"
              :options="storekeeper"
              v-model="selectedStorekeeper"
              label="name"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      ref="prepare-all-order-lines"
      no-close-on-backdrop
      cancel-variant="outline-secondary"
      centered
      size="lg"
      @hidden="onPrepareAllOrderLinesHidden"
      @ok="prepareAllOrderLines"
      :title="$t('Alert')"
    >
      <b-row>
        <b-col cols="12">
          <b-form-group :label="$t('Storekeeper')" style="font-size: 0.857rem">
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              input-id="sub-profile"
              :options="storekeeper"
              v-model="selectedStorekeeper"
              label="name"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      ref="SelectSellerRef"
      no-close-on-backdrop
      cancel-variant="outline-secondary"
      centered
      size="lg"
      @hidden="onPrepareAllOrderLinesHidden"
      @ok="prepareAllOrderLinesSeller"
      :title="$t('Alert')"
      >
        <b-row>
          <b-col>
            <b-form-group :label="$t('Seller')" style="font-size: 0.857rem">
              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                input-id="seller"
                :options="sellers"
                v-model="selectedSeller"
                label="name"
              />
            </b-form-group>
          </b-col>
        </b-row>
    </b-modal>
    <b-modal
      :ref="'orderline-' + data.item.id + '-prepare-line'"
      cancel-variant="outline-primary"
      :cancel-title="$t('Cancel')"
      :ok-title="$t('Prepare')"
      centered
      size="xl"
      :title="$t('Alert')"
      @hidden="onPrepareOrderLineHidden"
      @ok="confirmPreparingOrderLine"
    >
      <b-table
        id="scrolle-table"
        ref="table"
        show-empty
        :empty-text="$t('No matching records found')"
        :selectable="showSelect"
        hover
        select-mode="multi"
        :items="orderLines"
        :fields="fields"
        responsive
        @row-selected="onRowSelected"
        @row-clicked="onRowClicked"
      >
        <template #cell(selected)="{ rowSelected }">
          <template v-if="rowSelected">
            <i class="feather icon-disc primary" />
          </template>

          <template v-else>
            <i class="feather icon-circle" />
          </template>
        </template>
      </b-table>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BPagination,
  BDropdown,
  BDropdownItem,
  BModal,
  VBModal,
  BCardText,
  BBadge,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import instance from "@/libs/axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";

export default {
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  components: {
    BButton,
    BDropdown,
    BDropdownItem,
    BMedia,
    BBadge,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
    flatPickr,
    BPagination,
    BModal,
    VBModal,
    BCardText,
  },
  props: {
    data: Object,
    stateId: String,
  },
  data() {
    return {
      fields: [
        { key: "selected", label: this.$t("selected") },
        { key: "product", label: this.$t("product") },
        { key: "ordered_qty", label: this.$t("ordered qty") },
        { key: "piece", label: this.$t("ordered piece") },
        { key: "shipping_type", label: this.$t("shipping type") },
        { key: "shipping_date", label: this.$t("shipping date") },
        { key: "type", label: this.$t("order type") },
        { key: "emergency_degree", label: this.$t("emergency degree") },
      ],
      selectedOrderLine: [],
      orderLines: [],
      storekeeper: [],
      sellers: [],
      refetchData: null,
      selectedStorekeeper: null,
      selectedSeller: null,
      showSelect: true,
    };
  },
  created() {
    this.currentDate = new Date().toJSON().slice(0, 10);
  },
  methods: {
    /*rowClass(item) {
      if (this.currentDate !== item.shipping_date) return "row-disabled";
    },*/
    onRowClicked(item) {
      if (this.currentDate !== item.shipping_date) {
        this.showSelect = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Alert"),
            text: this.$t(
              "you cannot prepare this orderline because the delivery date is not today."
            ),
            icon: "InfoIcon",
            variant: "warning",
          },
        });
      } else {
        this.refetchData();
        this.showSelect = true;
      }
    },

    onPrepareAllOrderLinesHidden() {
      this.orderLines = [];
      this.showSelect = true;
    },
    onPrepareOrderLinesHidden() {
      this.showSelect = true;
    },
    async prepareAllOrderLines(bvModalEvt) {
      if (!this.selectedStorekeeper) {
        bvModalEvt.preventDefault();

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Alert"),
            text: this.$t("You must select a storekeeper."),
            icon: "InfoIcon",
            variant: "warning",
          },
        });
        return;
      }

      try {
        const data = this.getPreparedOrderLines();
        const res = await instance.post(
          `/orders/${this.data.item.id}/storekeepers/`,
          data
        );
        console.log(res.data.status);
        if(res.data.status ==0) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Alert"),
              text: this.$t(
                "you cannot prepare this orderlines because the delivery date is not today."
              ),
              icon: "InfoIcon",
              variant: "warning",
            },
          });
        }
        if (res.data.status ==1) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Success"),
              text: this.$t("Order lines have been prepared"),
              icon: "CheckIcon",
              variant: "success",
            },
          });
          this.$refs["prepare-all-order-lines"].hide();
          this.$refs["SelectSellerRef"].hide();
          this.refetchData();
          this.selectedStorekeeper = null;
          this.selectedSeller = null;
        }
        if(res.data.status ==2){
          this.$refs["SelectSellerRef"].show();
        }
        
      } catch (err) {
        const error = err.response
          ? Object.values(err.response.data)[0][0]
          : err.message;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: error ?? this.$t("An error has occurred. Please try again"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    async prepareAllOrderLinesSeller(bvModalEvt) {
        if (!this.selectedSeller) {
          bvModalEvt.preventDefault();

          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Alert"),
              text: this.$t("You must select a seller."),
              icon: "InfoIcon",
              variant: "warning",
            },
          });
          return;
        }

      try {
        const data = this.getPreparedOrderLines();
        const res = await instance.post(
          `/orders/${this.data.item.id}/storekeepers/seller/`,
          data
        );
        console.log(res.data.status);
        if(res.data.status ==0) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Alert"),
              text: this.$t(
                "you cannot prepare this orderlines because the delivery date is not today."
              ),
              icon: "InfoIcon",
              variant: "warning",
            },
          });
        }
        if (res.data.status ==1) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Success"),
              text: this.$t("Order lines have been prepared"),
              icon: "CheckIcon",
              variant: "success",
            },
          });
          this.$refs["prepare-all-order-lines"].hide();
          this.$refs["SelectSellerRef"].hide();
          this.refetchData();
          this.selectedStorekeeper = null;
          this.selectedSeller = null;
        }
        
      } catch (err) {
        const error = err.response
          ? Object.values(err.response.data)[0][0]
          : err.message;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: error ?? this.$t("An error has occurred. Please try again"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    async prepareSelectedOrderLines(bvModalEvt) {
      if (!this.selectedStorekeeper) {
        bvModalEvt.preventDefault();

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Alert"),
            text: this.$t("You must select storekeeper."),
            icon: "InfoIcon",
            variant: "warning",
          },
        });
        return;
      }

      try {
        const data = this.getPreparedOrderLines();
        const res = await instance.post(
          `/orders/${this.data.item.id}/storekeepers/`,
          data
        );
        console.log(res.data.status);
        if(res.data.status ==0) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Alert"),
              text: this.$t(
                "you cannot prepare this orderlines because the delivery date is not today."
              ),
              icon: "InfoIcon",
              variant: "warning",
            },
          });
        }
        if (res.data.status ==1) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t("Success"),
                text: this.$t("Order lines have been prepared"),
                icon: "CheckIcon",
                variant: "success",
              },
            });
            this.$refs["prepare-order-lines"].hide();
            this.$refs["SelectSellerRef"].hide();
            this.orderLines = [];
            this.refetchData();
            this.selectedStorekeeper = null;
            this.selectedSeller = null;
            await this.openCancelingOrderReasonByLineModel(this.data.item);
          }
        if(res.data.status ==2){
          this.$refs["SelectSellerRef"].show();
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: err.message,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    getPreparedOrderLines() {
      let data = {
        storekeeper: null,
        seller: null,
        order_lines: [],
      };
      data.storekeeper = parseInt(this.selectedStorekeeper.id);
      if(this.selectedSeller === null){
        data.seller = null;
      }else{
        data.seller = parseInt(this.selectedSeller.id);
      }
      for (var index in this.selectedOrderLine) {
        data.order_lines.push({
          id: parseInt(this.selectedOrderLine[index].id),
        });
      }
      console.log(data);
      return data;
    },

    async loadOrderStoreHeads(order) {
      try {
        const res = await Promise.all([
          instance.get(`/orders/${order.id}/storekeepers/`),
          instance.get("/parameters/vehicles/all/"),
          instance.get("/parameters/delivery-users/"),
          instance.get("/parameters/sellers/"),
        ]);
        this.storekeeper = res[0].data;
        this.vehicles = res[1].data;
        this.deliveryMen = res[2].data;
        this.sellers = res[3].data;
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: err.message,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    confirmPreparingOrderLine(bvModalEvt) {
      bvModalEvt.preventDefault();
      if (this.selectedOrderLine.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Alert"),
            text: this.$t("You must selecte at least 1 order line."),
            icon: "InfoIcon",
            variant: "warning",
          },
        });
        return;
      }
      this.$refs["prepare-order-lines"].show();
      // if (this.selectedOrderLine.length === 1) {
      // }
    },
    onRowSelected(items) {
      items.forEach((item) => {
        if (this.currentDate === item.shipping_date) {
          this.selectedOrderLine = items;
        }
      });
    },
    onPrepareOrderLineHidden() {
      this.orderLines = [];
    },
    async prepareOrder(order, refetchData) {
      this.refetchData = refetchData;
      const result = await this.$bvModal.msgBoxConfirm(
        this.$t("Please choose the suitable action for you."),
        {
          title: "Alert",
          size: "sm",
          okVariant: "primary",
          okTitle: this.$t("All lines"),
          cancelTitle: this.$t("By line"),
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        }
      );

      if (result === null || result === undefined) {
        return;
      }

      try {
        await this.openCancelingOrderReasonByLineModel(order);
        await this.loadOrderStoreHeads(order);

        if (result) {
          // all ligne
          this.openCancelingOrderReasonModel(order);
        } else {
          // by ligne
          this.$refs[`orderline-${order.id}-prepare-line`].show();
        }
      } catch (err) {
        const error = err.response
          ? Object.values(err.response.data)[0][0]
          : err.message;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: error ?? this.$t("An error has occurred. Please try again"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },

    openCancelingOrderReasonModel(order) {
      this.selectedOrderLine = this.orderLines;
      this.$refs["prepare-all-order-lines"].show();
    },
    async openCancelingOrderReasonByLineModel(order) {
      this.orderLines = [];
      const res = await instance.get(`/orders/${order.id}/order-lines/`, {
        params: { state_id: this.stateId, role: "operation_managment" },
      });

      res.data.forEach((orderLine) => {
        const {product_shortcut
        } = orderLine.product_combination;

        const {
          state,
          ordered_qty,
          piece,
          shipping_type,
          shipping_date,
          order_line_type,
          emergency_degree,
          seller_agent,
        } = orderLine;

        if(emergency_degree!=null){
            var emergency_degree_name = emergency_degree.name;
        }else{
            var emergency_degree_name = null;
        }

        if(seller_agent!=null){
            var seller_agent_id = seller_agent.id;
        }else{
            var seller_agent_id = null;
        }

        if (order_line_type === true) {
            var order_type = this.$t("Street sale");
        }else{
            var order_type = this.$t("Store sale");
        }

        this.orderLines.push({
          id: orderLine.id,
          product:product_shortcut,
          state: state.name,
          ordered_qty: ordered_qty,
          piece: piece,
          shipping_type: shipping_type.name,
          shipping_date: shipping_date,
          selected: true,
          type: order_type,
          order_id: orderLine.order.id,
          emergency_degree: emergency_degree_name,
          seller_agent_id: seller_agent_id,
          seller_agent: seller_agent,
        });

        this.selectedSeller = seller_agent;

      });
    },
  },
};
</script>
<style>
.row-disabled {
  background-color: #dddddd;
  cursor: not-allowed !important;
}
</style>
