<template>
  <div>
    <b-modal
      id="orderbyline"
      :ref="'orderline-' + data.item.id + '-prepare-line'"
      cancel-variant="outline-primary"
      :cancel-title="$t('Cancel')"
      :ok-title="$t('Confirm')"
      centered
      size="xl"
      :title="$t('Alert')"
      @hidden="onPrepareOrderLineHidden"
      @ok="confirmPreparingOrderLine"
    >
      <b-table
        id="scrolle-table"
        show-empty
        :empty-text="$t('No matching records found')"
        selectable
        select-mode="multi"
        :items="orderLines"
        :fields="fields"
        responsive
        @row-selected="onRowSelected"
      >
        <template #cell(selected)="{ rowSelected }">
          <template v-if="rowSelected">
            <i class="feather icon-disc primary" />
          </template>

          <template v-else>
            <i class="feather icon-circle" />
          </template>
        </template>
      </b-table>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BPagination,
  BDropdown,
  BDropdownItem,
  BModal,
  VBModal,
  BCardText,
  BBadge,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import instance from "@/libs/axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";

export default {
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  components: {
    BButton,
    BDropdown,
    BDropdownItem,
    BMedia,
    BBadge,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
    flatPickr,
    BPagination,
    BModal,
    VBModal,
    BCardText,
  },
  props: {
    data: Object,
    stateId: String,
  },
  data() {
    return {
      fields: [
        { key: "selected", label: this.$t("selected") },
        { key: "product", label: this.$t("product") },
        { key: "ordered_qty", label: this.$t("ordered qty") },
        { key: "piece", label: this.$t("ordered piece") },
        { key: "shipping_type", label: this.$t("shipping type") },
        { key: "shipping_date", label: this.$t("shipping date") },
        { key: "emergency_degree", label: this.$t("emergency degree") },
        
      ],
      selectedOrderLine: [],
      orderLines: [],
      refetchData: null,
    };
  },

  methods: {
    onPrepareAllOrderLinesHidden() {
      this.orderLines = [];
      this.selectedOrderLineLot = [];
      this.selectedStoreHead = null;
      this.selectedDock = null;
      this.selectedVehicle = null;
      this.selectedDeliveryMan = null;
    },
    onPrepareOrderLinesHidden() {
      this.selectedOrderLineLot = [];
      this.selectedStoreHead = null;
      this.selectedDock = null;
      this.selectedVehicle = null;
      this.selectedDeliveryMan = null;
    },
    async prepareAllOrderLines(bvModalEvt) {
      if (!this.selectedStorekeeper) {
        bvModalEvt.preventDefault();

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Alert"),
            text: this.$t("You must select storekeeper."),
            icon: "InfoIcon",
            variant: "warning",
          },
        });
        return;
      }

      try {
        const data = this.getPreparedOrderLines();
        await instance.post(`/orders/${this.data.item.id}/storekeepers/`, data);

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Success"),
            text: this.$t("All order lines have been prepared"),
            icon: "CheckIcon",
            variant: "success",
          },
        });

        this.$refs["prepare-all-order-lines"].hide();
        this.refetchData();
      } catch (err) {
        const error = err.response
          ? Object.values(err.response.data)[0][0]
          : err.message;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: error ?? this.$t("An error has occurred. Please try again"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    async prepareSelectedOrderLines(bvModalEvt) {
      if (!this.selectedStorekeeper) {
        bvModalEvt.preventDefault();

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Alert"),
            text: this.$t("You must select storekeeper."),
            icon: "InfoIcon",
            variant: "warning",
          },
        });
        return;
      }

      try {
        const data = this.getPreparedOrderLines();
        await instance.post(`/orders/${this.data.item.id}/storekeepers/`, data);

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Success"),
            text: this.$t("All order lines have been prepared"),
            icon: "CheckIcon",
            variant: "success",
          },
        });

        this.orderLines = [];
        this.refetchData();
        await this.openCancelingOrderReasonByLineModel(this.data.item);

        // this.$refs["prepare-order-lines"].hide();
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: err.message,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    getPreparedOrderLines() {
      let data = {
        storehead: null,
        storekeeper: null,
        vehicle: null,
        delivery_man: null,
        order_lines: [],
      };
      data.storekeeper = parseInt(this.selectedStorekeeper.id);
      data.vehicle = 1;
      data.delivery_man = 1;

      for (var index in this.selectedOrderLine) {
        data.order_lines.push({
          id: parseInt(this.selectedOrderLine[index].id),
          lot: this.selectedOrderLineLot[++index],
        });
      }

      return data;
    },

    async loadOrderStoreHeads(order) {
      try {
        const res = await Promise.all([
          instance.get(`/orders/${order.id}/storekeepers/`),
          instance.get("/parameters/vehicles/all/"),
          instance.get("/parameters/delivery-users/"),
        ]);
        this.storekeeper = res[0].data;
        this.vehicles = res[1].data;
        this.deliveryMen = res[2].data;
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: err.message,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },

    async confirmPreparingOrderLine(bvModalEvt) {
      const state = sessionStorage.getItem("state");
      bvModalEvt.preventDefault();
      if (this.selectedOrderLine.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Alert"),
            text: this.$t("You must selecte at least 1 order line."),
            icon: "InfoIcon",
            variant: "warning",
          },
        });
        return;
      }
      const resutls = await this.$bvModal.msgBoxConfirm(
        this.$t("Are you sure you want to save?"),
        {
          title: "Alert",
          size: "sm",
          okVariant: "primary",
          okTitle: this.$t("Yes"),
          cancelTitle: this.$t("No"),
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        }
      );

      if (!resutls) {
        return;
      }

      await instance.post(`/orders/${this.data.item.id}/order-lines/`, {
        order_lines: this.selectedOrderLine.map((e) => e.id),
        state: state,
      });
      this.$bvModal.hide("orderbyline");
      this.refetchData();
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t("Success"),
          text: this.$t("The order has been stored"),
          icon: "CheckIcon",
          variant: "success",
        },
      });

      // if (this.selectedOrderLine.length === 1) {
      // }
    },
    onRowSelected(items) {
      this.selectedOrderLine = items;
    },
    onPrepareOrderLineHidden() {
      this.orderLines = [];
    },
    async prepareOrder(order, refetchData, state) {
      this.refetchData = refetchData;
      const result = await this.$bvModal.msgBoxConfirm(
        this.$t("Please choose the suitable action for you."),
        {
          title: "Alert",
          size: "sm",
          okVariant: "primary",
          okTitle: this.$t("All lines"),
          cancelTitle: this.$t("By line"),
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        }
      );

      if (result === null || result === undefined) {
        return;
      }

      try {
        await this.openCancelingOrderReasonByLineModel(order);
        await this.loadOrderStoreHeads(order);

        if (result) {
          this.openCancelingOrderReasonModel(order, state);
        } else {
          this.$refs[`orderline-${order.id}-prepare-line`].show();
          sessionStorage.setItem("state", state);
        }
      } catch (err) {
        const error = err.response
          ? Object.values(err.response.data)[0][0]
          : err.message;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: error ?? this.$t("An error has occurred. Please try again"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },

    async openCancelingOrderReasonModel(order, state) {
      const resutls = await this.$bvModal.msgBoxConfirm(
        this.$t("Are you sure you want to save?"),
        {
          title: "Alert",
          size: "sm",
          okVariant: "primary",
          okTitle: this.$t("Yes"),
          cancelTitle: this.$t("No"),
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        }
      );

      if (!resutls) {
        return;
      }
      this.selectedOrderLine = this.orderLines;
      //this.$refs["prepare-all-order-lines"].show();
      await instance.post(`/orders/${this.data.item.id}/order-lines/`, {
        order_lines: this.selectedOrderLine.map((e) => e.id),
        state: state,
      });
      this.refetchData();
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t("Success"),
          text: this.$t("The orders has been stored"),
          icon: "CheckIcon",
          variant: "success",
        },
      });
    },

    async openCancelingOrderReasonByLineModel(order) {
      this.orderLines = [];
      const res = await instance.get(`/orders/${order.id}/order-lines/`, {
        params: { state_id: this.stateId, role: "operation_managment" },
      });

      res.data.forEach((orderLine) => {
        const {product_shortcut
        } = orderLine.product_combination;

        const {
          state,
          ordered_qty,
          piece,
          available_qty,
          available_piece,
          shipping_type,
          shipping_date,
          emergency_degree,
        } = orderLine;
        if(emergency_degree!=null){
            var emergency_degree_name = emergency_degree.name;
          }else{
            var emergency_degree_name = null;
          }
        this.orderLines.push({
          id: orderLine.id,
          product:product_shortcut,
          state: state.name,
          ordered_qty: ordered_qty,
          piece: piece,
          available_qty: available_qty,
          available_piece: available_piece,
          shipping_type: shipping_type.name,
          shipping_date: shipping_date,
          emergency_degree:emergency_degree_name,
          selected: true,
        });
      });
    },
  },
};
</script>
<style>
table#scrolle-table {
  white-space: nowrap;
}
</style>
