<template>
  <div>
    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>
        <!-- Field: Ref -->
        <b-col cols="12" md="3">
          <b-form-group :label="$t('Ref')" label-for="ref" debounce="500">
            <b-form-input id="ref" v-model="refValue" />
          </b-form-group>
        </b-col>

        <!-- Field: Client -->
        <b-col cols="12" md="3">
          <b-form-group :label="$t('Client')" label-for="client">
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="clients"
              label="name"
              input-id="client"
              v-model="clientValue"
              :loading ="loading"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Delivery Date -->
        <b-col cols="12" md="3">
          <b-form-group :label="$t('Shipping date')" label-for="shipping_date">
            <flat-pickr
              v-model="shippingDateValue"
              class="form-control"
              input-id="shipping_date"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Emergency Degree -->
        <b-col cols="12" md="3">
          <b-form-group
            :label="$t('Emergency degree')"
            label-for="emergency-degree"
          >
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="emergencyDegrees"
              v-model="emergencyDegreeValue"
              input-id="emergency-degree"
              @search="fetchEmergencyDegreeOptions"
              label="name"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
    <b-card no-body class="mb-0">
      <b-table
        id="scrolle-table"
        ref="refOrderListTable"
        class="position-relative"
        :items="fetchOrders"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('No matching records found')"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            class="btn-icon"
            @click="consulteOrder(data.item)"
          >
            <feather-icon icon="EyeIcon" size="16" />
            <!--Consult-->
          </b-button>
          &nbsp;
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            class="btn-icon"
            @click="prepareOrder(data.item, 'not_delivered_to_ready')"
          >
            <feather-icon icon="CheckIcon" size="16" />
            <!--Available-->
          </b-button>
          &nbsp;
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            class="btn-icon"
            @click="cancelOrder(data.item)"
          >
            <feather-icon icon="XCircleIcon" size="16" />
            <!--Cancel-->
          </b-button>

          <consulte-order
            :ref="'consulteOrder' + data.item.id + 'Ref'"
            :data="data"
            state-id="not_delivered"
          />
          <cancel-order
            :ref="'cancelOrder' + data.item.id + 'Ref'"
            :data="data"
            state-id="not_delivered"
          />
        </template>
      </b-table>
      <b-modal
        id="orderbyline"
        ref="ChangeByLigne"
        cancel-variant="outline-primary"
        :cancel-title="$t('Cancel')"
        :ok-title="$t('Confirm')"
        centered
        size="xl"
        :title="$t('Alert')"
        @hidden="onPrepareOrderLineHidden"
        @ok="confirmPreparingOrderLine"
      >
        <b-table
          id="scrolle-table"
          show-empty
          :empty-text="$t('No matching records found')"
          selectable
          select-mode="multi"
          :items="orderLines"
          :fields="fields"
          responsive
          @row-selected="onRowSelected"
        >
          <template #cell(selected)="{ rowSelected }">
            <template v-if="rowSelected">
              <i class="feather icon-disc primary" />
            </template>

            <template v-else>
              <i class="feather icon-circle" />
            </template>
          </template>
        </b-table>
      </b-modal>
      
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{
              $t("Pagination text", [dataMeta.from, dataMeta.to, dataMeta.of])
            }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalOrders"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BPagination,
  BDropdown,
  BDropdownItem,
  BModal,
  VBModal,
  BCardText,
  BBadge,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import instance from "@/libs/axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import useOrderTrackingList from "./useOrderTrackingList";
import Ripple from "vue-ripple-directive";
import CancelOrder from "./CancelOrder.vue";
import ConsulteOrder from "./ConsulteOrder.vue";
export default {
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  components: {
    BButton,
    BDropdown,
    BDropdownItem,
    BMedia,
    BBadge,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
    flatPickr,
    BPagination,
    BModal,
    VBModal,
    BCardText,
    CancelOrder,
    ConsulteOrder,
  },
  props: {
    clients: Array,
    emergencyDegrees: Array,
    subProfiles: Array,
    loading : Boolean,
  },
  data() {
    return {
      fields: [
        { key: "selected", label: this.$t("selected") },
        { key: "product", label: this.$t("product") },
        { key: "ordered_qty", label: this.$t("ordered qty") },
        { key: "piece", label: this.$t("ordered piece") },
        { key: "available_qty", label: this.$t("available qty") },
        { key: "available_piece", label: this.$t("available piece") },
        { key: "shipping_type", label: this.$t("shipping type") },
        { key: "shipping_date", label: this.$t("shipping date") },
        { key: "type", label: this.$t("order type") },
        { key: "emergency_degree", label: this.$t("emergency degree") },
      ],
      ref: null,
      selectedSubProfile: null,
      selectedClient: null,
      dateDefault: null,
      selectedEmergencyDegree: null,
      timeoutId: null,
      clientsRes: [],
      emergencyDegreesRes: [],
      selectedOrderLine: [],
      orderLines: [],
    };
  },

  methods: {
    refreshTable() {
      this.refetchData();
    },
    cancelOrder(order) {
      this.$refs[`cancelOrder${order.id}Ref`].cancelOrder(
        order,
        this.refetchData
      );
    },
    consulteOrder(order) {
      this.$refs[`consulteOrder${order.id}Ref`].consulteOrder(order);
    },
    async confirmPreparingOrderLine(bvModalEvt) {
      const state = sessionStorage.getItem("state");
      const order_id = sessionStorage.getItem("order_id");
      bvModalEvt.preventDefault();
      if (this.selectedOrderLine.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Alert"),
            text: this.$t("You must selecte at least 1 order line."),
            icon: "InfoIcon",
            variant: "warning",
          },
        });
        return;
      }
      const resutls = await this.$bvModal.msgBoxConfirm(
        this.$t("Are you sure you want to save?"),
        {
          title: "Alert",
          size: "sm",
          okVariant: "primary",
          okTitle: this.$t("Yes"),
          cancelTitle: this.$t("No"),
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        }
      );

      if (!resutls) {
        return;
      }
      await instance.post(`/orders/${order_id}/order-lines/`, {
        order_lines: this.selectedOrderLine.map((e) => e.id),
        state: state,
      });
      this.$bvModal.hide("orderbyline");
      this.refetchData();
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t("Success"),
          text: this.$t("The orderLigne status has been change"),
          icon: "CheckIcon",
          variant: "success",
        },
      });
    },
    onRowSelected(items) {
      this.selectedOrderLine = items;
    },
    onPrepareOrderLineHidden() {
      this.orderLines = [];
    },
    async prepareOrder(order, state) {
      const result = await this.$bvModal.msgBoxConfirm(
        this.$t("Please choose the suitable action for you."),
        {
          title: "Alert",
          size: "sm",
          okVariant: "primary",
          okTitle: this.$t("All lines"),
          cancelTitle: this.$t("By line"),
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        }
      );

      if (result === null || result === undefined) {
        return;
      }

      try {
        await this.openCancelingOrderReasonByLineModel(order);
        sessionStorage.setItem("order_id", order.id);
        if (result) {
          this.openCancelingOrderReasonModel(order, state);
        } else {
          this.$refs[`ChangeByLigne`].show();
          sessionStorage.setItem("state", state);
        }
      } catch (err) {
        const error = err.response
          ? Object.values(err.response.data)[0][0]
          : err.message;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: error ?? this.$t("An error has occurred. Please try again"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },

    async openCancelingOrderReasonModel(order, state) {
      const resutls = await this.$bvModal.msgBoxConfirm(
        this.$t("Are you sure you want to save?"),
        {
          title: "Alert",
          size: "sm",
          okVariant: "primary",
          okTitle: this.$t("Yes"),
          cancelTitle: this.$t("No"),
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        }
      );

      if (!resutls) {
        return;
      }
      this.selectedOrderLine = this.orderLines;
      //this.$refs["prepare-all-order-lines"].show();
      await instance.post(`/orders/${order.id}/order-lines/`, {
        order_lines: this.selectedOrderLine.map((e) => e.id),
        state: state,
      });
      this.refetchData();
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t("Success"),
          text: this.$t("The orders has been stored"),
          icon: "CheckIcon",
          variant: "success",
        },
      });
    },

    async openCancelingOrderReasonByLineModel(order) {
      this.orderLines = [];
      const res = await instance.get(`/orders/${order.id}/order-lines/`, {
        params: { state_id: "not_delivered", role: "operation_managment" },
      });

      res.data.forEach((orderLine) => {
        const {product_shortcut
        } = orderLine.product_combination;

        const {
          state,
          ordered_qty,
          piece,
          available_qty,
          available_piece,
          shipping_type,
          shipping_date,
          emergency_degree,
          order_line_type,
        } = orderLine;
        if(emergency_degree!=null){
          var emergency_degree_name = emergency_degree.name;
        }else{
          var emergency_degree_name = null;
        }
        if (order_line_type === true) {
          this.orderLines.push({
            id: orderLine.id,
            product:product_shortcut,
            state: state.name,
            ordered_qty: ordered_qty,
            piece: piece,
            available_qty: available_qty,
            available_piece: available_piece,
            shipping_type: shipping_type.name,
            shipping_date: shipping_date,
            emergency_degree:emergency_degree_name,
            type: this.$t("Street sale"),
            selected: true,
          });
        }else{
            this.orderLines.push({
            id: orderLine.id,
            product:product_shortcut,
            state: state.name,
            ordered_qty: ordered_qty,
            piece: piece,
            available_qty: available_qty,
            available_piece: available_piece,
            shipping_type: shipping_type.name,
            shipping_date: shipping_date,
            emergency_degree:emergency_degree_name,
            type: this.$t("Store sale"),
            selected: true,
          });
        }
      });
    },
    fetchClientOptions(search, loading) {
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(async () => {
        try {
          loading(true);
          const res = await instance.get("/parameters/clients/", {
            params: { search },
          });
          this.clientsRes = res.data.results;
        } catch (err) {
          const error = err.response
            ? Object.values(err.response.data)[0][0]
            : err.message;

          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Error"),
              text: error ?? this.$t("An error has occurred. Please try again"),
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        } finally {
          loading(false);
        }
      }, 500);
    },
    async fetchEmergencyDegreeOptions(search, loading) {
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(async () => {
        try {
          loading(true);
          const res = await instance.get("/parameters/emergency-degrees/", {
            params: { search },
          });
          this.emergencyDegreesRes = res.data.results;
        } catch (err) {
          const error = err.response
            ? Object.values(err.response.data)[0][0]
            : err.message;

          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Error"),
              text: error ?? this.$t("An error has occurred. Please try again"),
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        } finally {
          loading(false);
        }
      }, 500);
    },
  },

  setup(props) {
    const {
      fetchOrders,
      tableColumns,
      perPage,
      currentPage,
      totalOrders,
      dataMeta,
      perPageOptions,
      refValue,
      sortBy,
      isSortDirDesc,
      refOrderListTable,
      refetchData,
      subProfileValue,
      clientValue,
      shippingDateValue,
      emergencyDegreeValue,
    } = useOrderTrackingList("not_delivered");

    return {
      // Sidebar
      fetchOrders,
      tableColumns,
      perPage,
      currentPage,
      totalOrders,
      dataMeta,
      perPageOptions,
      refValue,
      sortBy,
      isSortDirDesc,
      refOrderListTable,
      refetchData,
      subProfileValue,
      clientValue,
      shippingDateValue,
      emergencyDegreeValue,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
