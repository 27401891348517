<template>
  <div>
    <b-modal
      :ref="'consulte-order-' + data.item.id"
      centered
      size="xl"
      @hidden="onConsulteOrderModalHidden"
      :title="$t('Consultation')"
      hide-footer
    >
      <b-table
        id="scrolle-table"
        select-mode="multi"
        show-empty
        :empty-text="$t('No matching records found')"
        :items="orderLines"
        :fields="fields"
        responsive
        @row-selected="onRowSelected"
      >
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="onVerifyClick(data.item)">
              <feather-icon
                icon="CopyIcon"
                size="18"
                class="align-middle text-body"
              />
              <span class="align-middle ml-50">{{ $t("Verify") }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BPagination,
  BDropdown,
  BDropdownItem,
  BModal,
  VBModal,
  BCardText,
  BBadge,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import instance from "@/libs/axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";

export default {
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  components: {
    BButton,
    BDropdown,
    BDropdownItem,
    BMedia,
    BBadge,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
    flatPickr,
    BPagination,
    BModal,
    VBModal,
    BCardText,
    BFormInvalidFeedback,
  },
  props: {
    data: Object,
    stateId: String,
  },
  data() {
    return {
      orderLines: [],
      order: null,
      lot: null,
      selectedOrderLine: null,
      fields: [
        "storekeeper",
        "product",
        "shipping type",
        "packing",
        "category",
        "glazurage",
        "brand",
        "packaging",
        "tranche",
        "lot",
        "ordered qty",
        "available qty",
        "unity",
        "state",
        "actions",
      ],
    };
  },
  methods: {
    async onVerifyClick(orderLine) {
      const result = await this.$bvModal.msgBoxConfirm(
        this.$t("Are you sure you want to verify the line you selected?"),
        {
          title: "Alert",
          size: "sm",
          okVariant: "primary",
          okTitle: this.$t("Yes"),
          cancelTitle: this.$t("No"),
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        }
      );
      if (!result) {
        return;
      }
      await instance.put(`/orders/order-lines/${orderLine.id}`);

      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t("Success"),
          text: this.$t("Order lines have been verified"),
          icon: "CheckIcon",
          variant: "success",
        },
      });
      await this.consulteOrder(this.order);
    },

    onConsulteOrderModalHidden() {
      this.orderLines = [];
    },

    async onRowSelected(orderLine) {
      this.selectedOrderLine = orderLine;
    },
    onCancelOrderLineHidden() {
      this.orderLines = [];
    },
    async consulteOrder(order) {
      try {
        this.orderLines = [];
        this.order = order;

        const res = await instance.get(`/orders/${order.id}/order-lines/`, {
          params: { state_id: this.stateId, role: "operation_managment" },
        });

        res.data.forEach((orderLine) => {
          const {
            product_name,
            packing,
            category,
            glazurage,
            brand,
            packaging,
            tranche,
            unity,
          } = orderLine.product_combination;
          const {
            state,
            ordered_qty,
            available_qty,
            shipping_type,
            shipping_date,
            storehead_agent,
            storekeeper_agent,
            lot,
          } = orderLine;
          this.orderLines.push({
            id: orderLine.id,
            product: product_name.name,
            packing: packing.name,
            category: category.name,
            glazurage: glazurage.name,
            brand: brand.name,
            packaging: packaging.name,
            tranche: tranche.name,
            unity: unity.name,
            state: state.name,
            lot: lot,
            ordered_qty: ordered_qty,
            available_qty: available_qty,
            shipping_type: shipping_type.name,
            shipping_type_id: shipping_type,
            shipping_date: `${shipping_date}`,
            storehead: storehead_agent.name,
            storekeeper: storekeeper_agent?.name,
          });
        });

        this.$refs[`consulte-order-${order.id}`].show();
      } catch (err) {
        const error = err.response
          ? Object.values(err.response.data)[0][0]
          : err.message;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: error ?? this.$t("An error has occurred. Please try again"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
  },
};
</script>
